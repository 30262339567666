import React, { FC } from 'react';

import { Dropdown, DropdownProps } from 'semantic-ui-react';

const countryOptions = [
  { key: 'AD', value: 'AD', text: 'Andorra' },
  { key: 'AE', value: 'AE', text: 'United Arab Emirates' },
  { key: 'AF', value: 'AF', text: 'Afghanistan' },
  { key: 'AG', value: 'AG', text: 'Antigua and Barbuda' },
  { key: 'AI', value: 'AI', text: 'Anguilla' },
  { key: 'AL', value: 'AL', text: 'Albania' },
  { key: 'AM', value: 'AM', text: 'Armenia' },
  { key: 'AO', value: 'AO', text: 'Angola' },
  { key: 'AQ', value: 'AQ', text: 'Antarctica' },
  { key: 'AR', value: 'AR', text: 'Argentina' },
  { key: 'AS', value: 'AS', text: 'American Samoa' },
  { key: 'AT', value: 'AT', text: 'Austria' },
  { key: 'AU', value: 'AU', text: 'Australia' },
  { key: 'AW', value: 'AW', text: 'Aruba' },
  { key: 'AX', value: 'AX', text: 'Åland Islands' },
  { key: 'AZ', value: 'AZ', text: 'Azerbaijan' },
  { key: 'BA', value: 'BA', text: 'Bosnia and Herzegovina' },
  { key: 'BB', value: 'BB', text: 'Barbados' },
  { key: 'BD', value: 'BD', text: 'Bangladesh' },
  { key: 'BE', value: 'BE', text: 'Belgium' },
  { key: 'BF', value: 'BF', text: 'Burkina Faso' },
  { key: 'BG', value: 'BG', text: 'Bulgaria' },
  { key: 'BH', value: 'BH', text: 'Bahrain' },
  { key: 'BI', value: 'BI', text: 'Burundi' },
  { key: 'BJ', value: 'BJ', text: 'Benin' },
  { key: 'BL', value: 'BL', text: 'Saint Barthélemy' },
  { key: 'BM', value: 'BM', text: 'Bermuda' },
  { key: 'BN', value: 'BN', text: 'Brunei Darussalam' },
  { key: 'BO', value: 'BO', text: 'Bolivia' },
  { key: 'BQ', value: 'BQ', text: 'Bonaire, Sint Eustatius and Saba' },
  { key: 'BR', value: 'BR', text: 'Brazil' },
  { key: 'BS', value: 'BS', text: 'Bahamas' },
  { key: 'BT', value: 'BT', text: 'Bhutan' },
  { key: 'BV', value: 'BV', text: 'Bouvet Island' },
  { key: 'BW', value: 'BW', text: 'Botswana' },
  { key: 'BY', value: 'BY', text: 'Belarus' },
  { key: 'BZ', value: 'BZ', text: 'Belize' },
  { key: 'CA', value: 'CA', text: 'Canada' },
  { key: 'CC', value: 'CC', text: 'Cocos (Keeling) Islands' },
  { key: 'CD', value: 'CD', text: 'Congo, the Democratic Republic of the' },
  { key: 'CF', value: 'CF', text: 'Central African Republic' },
  { key: 'CG', value: 'CG', text: 'Congo' },
  { key: 'CH', value: 'CH', text: 'Switzerland' },
  { key: 'CI', value: 'CI', text: "Côte d'Ivoire" },
  { key: 'CK', value: 'CK', text: 'Cook Islands' },
  { key: 'CL', value: 'CL', text: 'Chile' },
  { key: 'CM', value: 'CM', text: 'Cameroon' },
  { key: 'CN', value: 'CN', text: 'China' },
  { key: 'CO', value: 'CO', text: 'Colombia' },
  { key: 'CR', value: 'CR', text: 'Costa Rica' },
  { key: 'CU', value: 'CU', text: 'Cuba' },
  { key: 'CV', value: 'CV', text: 'Cape Verde' },
  { key: 'CW', value: 'CW', text: 'Curaçao' },
  { key: 'CX', value: 'CX', text: 'Christmas Island' },
  { key: 'CY', value: 'CY', text: 'Cyprus' },
  { key: 'CZ', value: 'CZ', text: 'Czech Republic' },
  { key: 'DE', value: 'DE', text: 'Germany' },
  { key: 'DJ', value: 'DJ', text: 'Djibouti' },
  { key: 'DK', value: 'DK', text: 'Denmark' },
  { key: 'DM', value: 'DM', text: 'Dominica' },
  { key: 'DO', value: 'DO', text: 'Dominican Republic' },
  { key: 'DZ', value: 'DZ', text: 'Algeria' },
  { key: 'EC', value: 'EC', text: 'Ecuador' },
  { key: 'EE', value: 'EE', text: 'Estonia' },
  { key: 'EG', value: 'EG', text: 'Egypt' },
  { key: 'EH', value: 'EH', text: 'Western Sahara' },
  { key: 'ER', value: 'ER', text: 'Eritrea' },
  { key: 'ES', value: 'ES', text: 'Spain' },
  { key: 'ET', value: 'ET', text: 'Ethiopia' },
  { key: 'FI', value: 'FI', text: 'Finland' },
  { key: 'FJ', value: 'FJ', text: 'Fiji' },
  { key: 'FK', value: 'FK', text: 'Falkland Islands (Malvinas)' },
  { key: 'FM', value: 'FM', text: 'Micronesia, Federated States of' },
  { key: 'FO', value: 'FO', text: 'Faroe Islands' },
  { key: 'FR', value: 'FR', text: 'France' },
  { key: 'GA', value: 'GA', text: 'Gabon' },
  { key: 'GB', value: 'GB', text: 'United Kingdom' },
  { key: 'GD', value: 'GD', text: 'Grenada' },
  { key: 'GE', value: 'GE', text: 'Georgia' },
  { key: 'GF', value: 'GF', text: 'French Guiana' },
  { key: 'GG', value: 'GG', text: 'Guernsey' },
  { key: 'GH', value: 'GH', text: 'Ghana' },
  { key: 'GI', value: 'GI', text: 'Gibraltar' },
  { key: 'GL', value: 'GL', text: 'Greenland' },
  { key: 'GM', value: 'GM', text: 'Gambia' },
  { key: 'GN', value: 'GN', text: 'Guinea' },
  { key: 'GP', value: 'GP', text: 'Guadeloupe' },
  { key: 'GQ', value: 'GQ', text: 'Equatorial Guinea' },
  { key: 'GR', value: 'GR', text: 'Greece' },
  {
    key: 'GS',
    value: 'GS',
    text: 'South Georgia and the South Sandwich Islands',
  },
  { key: 'GT', value: 'GT', text: 'Guatemala' },
  { key: 'GU', value: 'GU', text: 'Guam' },
  { key: 'GW', value: 'GW', text: 'Guinea-Bissau' },
  { key: 'GY', value: 'GY', text: 'Guyana' },
  { key: 'HK', value: 'HK', text: 'Hong Kong' },
  { key: 'HM', value: 'HM', text: 'Heard Island and McDonald Islands' },
  { key: 'HN', value: 'HN', text: 'Honduras' },
  { key: 'HR', value: 'HR', text: 'Croatia' },
  { key: 'HT', value: 'HT', text: 'Haiti' },
  { key: 'HU', value: 'HU', text: 'Hungary' },
  { key: 'ID', value: 'ID', text: 'Indonesia' },
  { key: 'IE', value: 'IE', text: 'Ireland' },
  { key: 'IL', value: 'IL', text: 'Israel' },
  { key: 'IM', value: 'IM', text: 'Isle of Man' },
  { key: 'IN', value: 'IN', text: 'India' },
  { key: 'IO', value: 'IO', text: 'British Indian Ocean Territory' },
  { key: 'IQ', value: 'IQ', text: 'Iraq' },
  { key: 'IR', value: 'IR', text: 'Iran, Islamic Republic of' },
  { key: 'IS', value: 'IS', text: 'Iceland' },
  { key: 'IT', value: 'IT', text: 'Italy' },
  { key: 'JE', value: 'JE', text: 'Jersey' },
  { key: 'JM', value: 'JM', text: 'Jamaica' },
  { key: 'JO', value: 'JO', text: 'Jordan' },
  { key: 'JP', value: 'JP', text: 'Japan' },
  { key: 'KE', value: 'KE', text: 'Kenya' },
  { key: 'KG', value: 'KG', text: 'Kyrgyzstan' },
  { key: 'KH', value: 'KH', text: 'Cambodia' },
  { key: 'KI', value: 'KI', text: 'Kiribati' },
  { key: 'KM', value: 'KM', text: 'Comoros' },
  { key: 'KN', value: 'KN', text: 'Saint Kitts and Nevis' },
  { key: 'KP', value: 'KP', text: 'North Korea' },
  { key: 'KR', value: 'KR', text: 'South Korea' },
  { key: 'KW', value: 'KW', text: 'Kuwait' },
  { key: 'KY', value: 'KY', text: 'Cayman Islands' },
  { key: 'KZ', value: 'KZ', text: 'Kazakhstan' },
  { key: 'LA', value: 'LA', text: "Lao People's Democratic Republic" },
  { key: 'LB', value: 'LB', text: 'Lebanon' },
  { key: 'LC', value: 'LC', text: 'Saint Lucia' },
  { key: 'LI', value: 'LI', text: 'Liechtenstein' },
  { key: 'LK', value: 'LK', text: 'Sri Lanka' },
  { key: 'LR', value: 'LR', text: 'Liberia' },
  { key: 'LS', value: 'LS', text: 'Lesotho' },
  { key: 'LT', value: 'LT', text: 'Lithuania' },
  { key: 'LU', value: 'LU', text: 'Luxembourg' },
  { key: 'LV', value: 'LV', text: 'Latvia' },
  { key: 'LY', value: 'LY', text: 'Libya' },
  { key: 'MA', value: 'MA', text: 'Morocco' },
  { key: 'MC', value: 'MC', text: 'Monaco' },
  { key: 'MD', value: 'MD', text: 'Moldova, Republic of' },
  { key: 'ME', value: 'ME', text: 'Montenegro' },
  { key: 'MF', value: 'MF', text: 'Saint Martin (French part)' },
  { key: 'MG', value: 'MG', text: 'Madagascar' },
  { key: 'MH', value: 'MH', text: 'Marshall Islands' },
  { key: 'MK', value: 'MK', text: 'North Macedonia, Republic of' },
  { key: 'ML', value: 'ML', text: 'Mali' },
  { key: 'MM', value: 'MM', text: 'Myanmar' },
  { key: 'MN', value: 'MN', text: 'Mongolia' },
  { key: 'MO', value: 'MO', text: 'Macao' },
  { key: 'MP', value: 'MP', text: 'Northern Mariana Islands' },
  { key: 'MQ', value: 'MQ', text: 'Martinique' },
  { key: 'MR', value: 'MR', text: 'Mauritania' },
  { key: 'MS', value: 'MS', text: 'Montserrat' },
  { key: 'MT', value: 'MT', text: 'Malta' },
  { key: 'MU', value: 'MU', text: 'Mauritius' },
  { key: 'MV', value: 'MV', text: 'Maldives' },
  { key: 'MW', value: 'MW', text: 'Malawi' },
  { key: 'MX', value: 'MX', text: 'Mexico' },
  { key: 'MY', value: 'MY', text: 'Malaysia' },
  { key: 'MZ', value: 'MZ', text: 'Mozambique' },
  { key: 'NA', value: 'NA', text: 'Namibia' },
  { key: 'NC', value: 'NC', text: 'New Caledonia' },
  { key: 'NE', value: 'NE', text: 'Niger' },
  { key: 'NF', value: 'NF', text: 'Norfolk Island' },
  { key: 'NG', value: 'NG', text: 'Nigeria' },
  { key: 'NI', value: 'NI', text: 'Nicaragua' },
  { key: 'NL', value: 'NL', text: 'Netherlands' },
  { key: 'NO', value: 'NO', text: 'Norway' },
  { key: 'NP', value: 'NP', text: 'Nepal' },
  { key: 'NR', value: 'NR', text: 'Nauru' },
  { key: 'NU', value: 'NU', text: 'Niue' },
  { key: 'NZ', value: 'NZ', text: 'New Zealand' },
  { key: 'OM', value: 'OM', text: 'Oman' },
  { key: 'PA', value: 'PA', text: 'Panama' },
  { key: 'PE', value: 'PE', text: 'Peru' },
  { key: 'PF', value: 'PF', text: 'French Polynesia' },
  { key: 'PG', value: 'PG', text: 'Papua New Guinea' },
  { key: 'PH', value: 'PH', text: 'Philippines' },
  { key: 'PK', value: 'PK', text: 'Pakistan' },
  { key: 'PL', value: 'PL', text: 'Poland' },
  { key: 'PM', value: 'PM', text: 'Saint Pierre and Miquelon' },
  { key: 'PN', value: 'PN', text: 'Pitcairn' },
  { key: 'PR', value: 'PR', text: 'Puerto Rico' },
  { key: 'PS', value: 'PS', text: 'Palestine, State of' },
  { key: 'PT', value: 'PT', text: 'Portugal' },
  { key: 'PW', value: 'PW', text: 'Palau' },
  { key: 'PY', value: 'PY', text: 'Paraguay' },
  { key: 'QA', value: 'QA', text: 'Qatar' },
  { key: 'RE', value: 'RE', text: 'Réunion' },
  { key: 'RO', value: 'RO', text: 'Romania' },
  { key: 'RS', value: 'RS', text: 'Serbia' },
  { key: 'RU', value: 'RU', text: 'Russian Federation' },
  { key: 'RW', value: 'RW', text: 'Rwanda' },
  { key: 'SA', value: 'SA', text: 'Saudi Arabia' },
  { key: 'SB', value: 'SB', text: 'Solomon Islands' },
  { key: 'SC', value: 'SC', text: 'Seychelles' },
  { key: 'SD', value: 'SD', text: 'Sudan' },
  { key: 'SE', value: 'SE', text: 'Sweden' },
  { key: 'SG', value: 'SG', text: 'Singapore' },
  {
    key: 'SH',
    value: 'SH',
    text: 'Saint Helena, Ascension and Tristan da Cunha',
  },
  { key: 'SI', value: 'SI', text: 'Slovenia' },
  { key: 'SJ', value: 'SJ', text: 'Svalbard and Jan Mayen' },
  { key: 'SK', value: 'SK', text: 'Slovakia' },
  { key: 'SL', value: 'SL', text: 'Sierra Leone' },
  { key: 'SM', value: 'SM', text: 'San Marino' },
  { key: 'SN', value: 'SN', text: 'Senegal' },
  { key: 'SO', value: 'SO', text: 'Somalia' },
  { key: 'SR', value: 'SR', text: 'Suriname' },
  { key: 'SS', value: 'SS', text: 'South Sudan' },
  { key: 'ST', value: 'ST', text: 'Sao Tome and Principe' },
  { key: 'SV', value: 'SV', text: 'El Salvador' },
  { key: 'SX', value: 'SX', text: 'Sint Maarten (Dutch part)' },
  { key: 'SY', value: 'SY', text: 'Syrian Arab Republic' },
  { key: 'SZ', value: 'SZ', text: 'Swaziland' },
  { key: 'TC', value: 'TC', text: 'Turks and Caicos Islands' },
  { key: 'TD', value: 'TD', text: 'Chad' },
  { key: 'TF', value: 'TF', text: 'French Southern Territories' },
  { key: 'TG', value: 'TG', text: 'Togo' },
  { key: 'TH', value: 'TH', text: 'Thailand' },
  { key: 'TJ', value: 'TJ', text: 'Tajikistan' },
  { key: 'TK', value: 'TK', text: 'Tokelau' },
  { key: 'TL', value: 'TL', text: 'Timor-Leste' },
  { key: 'TM', value: 'TM', text: 'Turkmenistan' },
  { key: 'TN', value: 'TN', text: 'Tunisia' },
  { key: 'TO', value: 'TO', text: 'Tonga' },
  { key: 'TR', value: 'TR', text: 'Turkey' },
  { key: 'TT', value: 'TT', text: 'Trinidad and Tobago' },
  { key: 'TV', value: 'TV', text: 'Tuvalu' },
  { key: 'TW', value: 'TW', text: 'Taiwan' },
  { key: 'TZ', value: 'TZ', text: 'Tanzania, United Republic of' },
  { key: 'UA', value: 'UA', text: 'Ukraine' },
  { key: 'UG', value: 'UG', text: 'Uganda' },
  { key: 'UM', value: 'UM', text: 'United States Minor Outlying Islands' },
  { key: 'US', value: 'US', text: 'United States' },
  { key: 'UY', value: 'UY', text: 'Uruguay' },
  { key: 'UZ', value: 'UZ', text: 'Uzbekistan' },
  { key: 'VA', value: 'VA', text: 'Holy See (Vatican City State)' },
  { key: 'VC', value: 'VC', text: 'Saint Vincent and the Grenadines' },
  { key: 'VE', value: 'VE', text: 'Venezuela, Bolivarian Republic of' },
  { key: 'VG', value: 'VG', text: 'Virgin Islands, British' },
  { key: 'VI', value: 'VI', text: 'Virgin Islands, U.S.' },
  { key: 'VN', value: 'VN', text: 'Viet Nam' },
  { key: 'VU', value: 'VU', text: 'Vanuatu' },
  { key: 'WF', value: 'WF', text: 'Wallis and Futuna' },
  { key: 'WS', value: 'WS', text: 'Samoa' },
  { key: 'YE', value: 'YE', text: 'Yemen' },
  { key: 'YT', value: 'YT', text: 'Mayotte' },
  { key: 'ZA', value: 'ZA', text: 'South Africa' },
  { key: 'ZM', value: 'ZM', text: 'Zambia' },
  { key: 'ZW', value: 'ZW', text: 'Zimbabwe' },
].sort((a, b) => a.text.localeCompare(b.text));

const CountrySelect: FC<Omit<DropdownProps, 'options'>> = (props) => (
  <Dropdown options={countryOptions} {...props} />
);

export default CountrySelect;
